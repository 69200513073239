
































import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import { Notification } from "@/models/notification.interface";
import Loader from "@/components/General/Loader.vue";
import { VueEditor } from "vue2-editor";
import { FAQ as IFAQ } from "@/models/faq.interface";

@Component({
  components: { Loader, VueEditor },
})
export default class FAQ extends mixins(Navigation) {
  loader = false;
  panel = 0;

  private async created() {
    window.scrollTo(0, 0);
    this.loader = true;
    await this.$store
      .dispatch("faq/getFAQToClientSide")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Pages.FAQ.error"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loader = false;
      });
  }

  private get faqs(): IFAQ[] {
    return this.$store.getters["faq/getFAQ"];
  }
}
